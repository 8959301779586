import React from "react";

const Timetable = () => {
  return (
    <>
      <h1>Расписание курса</h1>
      <p>
        Следующее расписание курса предназначено для поддержания непрерывности
        практики. Для достижения наилучших результатов необходимо соблюдать его
        как можно точнее.
      </p>

      <table>
        <tr>
          <td>4:00</td>
          <td>Утренний колокол – подъем</td>
        </tr>

        <tr>
          <td>4:30-6:30</td>
          <td>Медитация в зале или в своей комнате</td>
        </tr>

        <tr>
          <td>6:30-8:00</td>
          <td>Перерыв на завтрак</td>
        </tr>

        <tr>
          <td>8:00-9:00</td>
          <td>Общая медитация в зале</td>
        </tr>

        <tr>
          <td>9:00-11:00</td>
          <td>Медитация в зале или в комнате (по усмотрению учителя)</td>
        </tr>

        <tr>
          <td>11:00-12:00</td>
          <td>Перерыв на обед</td>
        </tr>

        <tr>
          <td>12:00-13:00</td>
          <td>Отдых и личные встречи с учителем</td>
        </tr>

        <tr>
          <td>13:00-14:30</td>
          <td>Медитация в зале или в своей комнате</td>
        </tr>

        <tr>
          <td>14:30-15:30</td>
          <td>Общая медитация в зале</td>
        </tr>

        <tr>
          <td>15:30-17:00</td>
          <td>Медитация в зале или в комнате (по усмотрению учителя)</td>
        </tr>

        <tr>
          <td>17:00-18:00</td>
          <td>Перерыв на чай</td>
        </tr>

        <tr>
          <td>18:00-19:00</td>
          <td>Общая медитация в зале</td>
        </tr>

        <tr>
          <td>19:00-20:15</td>
          <td>Беседа учителя в зале</td>
        </tr>

        <tr>
          <td>20:15-21:00</td>
          <td>Общая медитация в зале</td>
        </tr>

        <tr>
          <td>21:00-21:30</td>
          <td>Время для вопросов в зале</td>
        </tr>

        <tr>
          <td>21:30</td>
          <td>Отход ко сну в своей комнате. Выключается свет</td>
        </tr>
      </table>
    </>
  );
};

export default Timetable;
