import React, { useState, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import ofertaMd from './oferta.md'


const Oferta = () => {
const [postMarkdown, setPostMarkdown] = useState('');

// useEffect with an empty dependency array (`[]`) runs only once
useEffect(() => {
  fetch(ofertaMd)
    .then((response) => response.text())
    .then((text) => {
      // Logs a string of Markdown content.
      // Now you could use e.g. <rexxars/react-markdown> to render it.
      // console.log(text);
      setPostMarkdown(text);
    });
}, []);

  return <ReactMarkdown>{postMarkdown}</ReactMarkdown> ;
}

export default Oferta;
