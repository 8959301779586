import React from 'react'
import 'bulma/css/bulma.css'
import Oferta from './Oferta'
import Timetable from './Timetable'
import PressRelease from './PressRelease'
import './App.css'
import { Routes, Route, Outlet, Link, NavLink, useResolvedPath, useMatch } from "react-router-dom"

const App = () => {
  return (
    <div>
      {/* Routes nest inside one another. Nested route paths build upon
            parent route paths, and nested route elements render inside
            parent route elements. See the note about <Outlet> below. */}
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Oferta />} />
          <Route path="timetable" element={<Timetable />} />
          <Route path="press-release" element={<PressRelease />} />

          {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
        </Route>
      </Routes>
    </div>
  )
}

function Layout() {
  return (
      <section className='section'>
        <div className='container'>
          <div className='columns  is-centered'>
            <div className='column has-text-centered is-half' >
              <h1 className='title is-1' style={{ color: 'gray' }}>Семинары и Курсы</h1>
              <h2 className='subtitle'>seminary-kursy.by</h2>
            </div>
          </div>
          <div className='columns is-centered'>
            <div className='column is-two-thirds' >
              <div className="tabs is-centered is-boxed is-medium">
                <ul>
                    <CustomNavLink
                      to="/"
                    >
                      Публичная оферта
                    </CustomNavLink>
                    <CustomNavLink
                      to="/timetable"
                    >
                     Расписание
                    </CustomNavLink>
                    <CustomNavLink to="/press-release">Пресс-релиз</CustomNavLink>
                </ul>
              </div>
              <div className=' content'>
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

function CustomNavLink({ children, to, ...props }) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <li
      className={match ? 'is-active': ''}
    >
      <Link
        to={to}
        {...props}
      >
        {children}
      </Link>
    </li>
  );
}

export default App
